.react-calendar-timeline .rct-dateHeader {
    border: none;
    background-color: unset;
}

.react-calendar-timeline .rct-calendar-header {
    border: none;
}

.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
    background: unset;
}

.react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd {
    background: unset;
}

.interval-date {
    width: 27px;
    height: 27px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    pointer-events: none;
    position: absolute;
    display: grid;
    align-items: center;
}

#intervalPropsAttr {
    display: flex;
    justify-content: center;

    @media screen and (max-width: 826px) {
        display: none;
    }
}

.rct-header-root {
    border-top-left-radius: 10px 10px;
    border-top-right-radius: 10px 10px;
}

.rct-horizontal-lines .highlight:last-child {
    border-bottom: none;
}

.rct-outer .rct-sidebar div:first-child .rct-sidebar-row:last-child {
    border-bottom: none;
}

.rct-sidebar {
    box-shadow: 4px 0 26px 0 rgba(0, 0, 0, 0.3);
    border-right: unset !important;
}

.upcoming-events {
    -moz-border-radius: 75px;
    -webkit-border-radius: 75px;
    pointer-events: auto;
    width: 9px;
    height: 9px;
    position: absolute;
    top: -13px;
    margin-left: -4px;
}

.rct-calendar-header {
    align-items: end;
    height: 100px;
}

.rct-calendar-header > div:nth-child(2) {
    bottom: 0;
    margin-top: 35px;
}

.date-header-interval {
    text-align: center;
    margin-top: 17px;
    font-size: 20px;
    position: absolute;
}

.current-date {
    color: white;
    background-color: black;
    width: 27px;
    height: 27px;
    border-radius: 50%;
}

.rct-sidebar-row {
    padding: 0 !important;
}

.rct-day-0,
.rct-day-6 {
    background-color: rgba(128, 128, 128, 0.1) !important;
}

.rct-header-root {
    width: fit-content !important;
    position: sticky;
    top: 0;
    z-index: 91;
}
